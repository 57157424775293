import { getCheapestSelectedFareclass } from "./fareClass";
import { getCityNameAndAirportCodeFromTerminus } from "./terminus";
import { transformToStringifiedPackagesAvailabilityQuery } from "b2b-base/src/utils/queryStringHelpers";
import { PATH_PACKAGES } from "./urlPaths";
import {
  FareclassOptionFilter,
  FareclassShelfBrandName,
  ITripTerminus,
  PackagesEntryTypeEnum,
  SliceStopCountFilter,
} from "redmond";

export function generatePackagesCTAUrl(
  {
    fareClass,
    destination,
    origin,
    fromDate,
    untilDate,
    stopsOption,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    matchesMobile,
    entryPoint,
  }: {
    fareClass: FareclassOptionFilter;
    destination: ITripTerminus | null;
    origin: ITripTerminus | null;
    fromDate: Date | null;
    untilDate?: Date | null;
    stopsOption?: SliceStopCountFilter;
    adultsCount: number;
    childrenCount?: number;
    infantsInSeatCount?: number;
    infantsOnLapCount?: number;
    matchesMobile: boolean;
    entryPoint: PackagesEntryTypeEnum;
  },
  defaultFare: FareclassShelfBrandName = "basic"
): string {
  const cheapestSelectedFareClass = getCheapestSelectedFareclass(
    fareClass,
    defaultFare
  );

  const destinationCity = destination
    ? getCityNameAndAirportCodeFromTerminus(destination)[0]
    : "";

  return `${PATH_PACKAGES}${
    matchesMobile ? `/search/` : ""
  }${transformToStringifiedPackagesAvailabilityQuery({
    origin: origin?.id?.code?.code,
    destination: destinationCity,
    fromDate,
    untilDate,
    stopsOption: stopsOption!,
    fareClass: cheapestSelectedFareClass,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    entryPoint,
  })}`;
}
