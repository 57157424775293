import React from "react";
import { Box, DialogActions, DialogContent } from "@material-ui/core";
import { CorpSessionInfo, PolicyTier, ProductType, SessionInfo } from "redmond";
import { PolicyDetailsContent } from "halifax";
import { StepProps } from "../types";
import Header from "../Header";

import baseClasses from "../../styles.module.css";
import { ActionButton } from "halifax";

export interface IProps extends StepProps {
  policies: PolicyTier;
  productType: ProductType;
  sessionInfo?: SessionInfo | CorpSessionInfo;
}

export const ReviewPolicy = ({
  primaryAction,
  secondaryAction,
  onClose,
  policies,
  productType,
  sessionInfo,
}: IProps): React.ReactElement => (
  <Box className={baseClasses.stepWrap}>
    <Header
      title="Review travel policy"
      subtitle="All options within these guidelines are included in your company travel policy."
      currentStep={2}
      onClose={onClose}
    />
    <DialogContent>
      <PolicyDetailsContent
        policies={policies}
        productType={productType}
        sessionInfo={sessionInfo}
      />
    </DialogContent>
    <DialogActions>
      <ActionButton
        onClick={secondaryAction}
        defaultStyle="h4r-secondary"
        message="Back"
      />
      <ActionButton
        onClick={primaryAction}
        message="Got it!"
        defaultStyle="h4r-primary"
      />
    </DialogActions>
  </Box>
);
